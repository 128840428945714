import React from 'react';

import ResumeBodyTech from './ResumeBodyTech';
import ResumeBodyWork from './ResumeBodyWork';
import './ResumeBody.css';

const styles = {
    containerStyle: {
        margin: '0 auto',
        maxWidth: 1120,
        paddingTop: '50px',
        paddingBottom: '50px',
    },
};

const ResumeBody = () => (
    <div style={{ backgroundColor: '#fbfbfb' }}>
        <div style={styles.containerStyle}>
            <ResumeBodyTech />
            <ResumeBodyWork />
        </div>
    </div>
);

export default ResumeBody;
