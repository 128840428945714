import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import ResumeHeaderText from '../components/resume/ResumeHeaderText';
import ResumeBody from '../components/resume/ResumeBody';
import ResumeDownloadFooter from '../components/resume/ResumeDownloadFooter';

const ResumePage = () => (
    <div>
        <Layout>
            <SEO title="Resume" keywords={['snguyenthanh', 'resume', 'Son Nguyen']} />
            <ResumeHeaderText />
            <ResumeBody />
            <ResumeDownloadFooter />
        </Layout>
    </div>
);

export default ResumePage;
