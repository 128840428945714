import React from 'react';

import { Container, Row, Col } from 'reactstrap';
import './ResumeBodyWork.css';
import '../../css/UnderlineLink.scss';

const workExps = [
    {
        position: 'Software Engineer Intern',
        company: 'Zendesk',
        link: 'https://www.zendesk.com/chat/',
        location: 'Singapore',
        period: '05/2019 - 08/2019',
        description:
            <div className="tech-work-description">
                Worked in the core back-end team of Zendesk Chat
                <ul className="xs-margin-bottom">
                    <li>
                        Implemented in-memory cache for internal feature gates on the central Live
                        Chat servers, reducing network bandwidth usage to feature-gate servers
                        by 40%.
                    </li>
                    <li>
                        Optimized an internal utility library, which reduces docker images’ sizes
                        of 21 internal services by 14MB.
                    </li>
                    <li>
                        Engaged in improvements of Ticket integration - improve a retry mechanism
                        and a Cloudflare-related connection issue, reducing the number of
                        TimeoutError occurrences by ~50%.
                    </li>
                </ul>
            </div>,
    },
    {
        position: 'Backend Software Engineer Intern',
        company: 'InnoSenze Pte. Ltd.',
        link: 'https://www.innosenze.com/',
        location: 'Singapore',
        period: '06/2018 - 11/2018',
        description:
            <div className="tech-work-description">
                Implemented an ETL system to extract patents for data pre-processing pipeline:
                <ul className="xs-margin-bottom">
                    <li>
                        Structured, cleaned and performed analyses on GBs of USPTO and WIPO raw
                         datasets, on AWS EC2, using LXML and BeautifulSoup.
                    </li>
                    <li>
                        Refactored XML parsers to reduce the memory usage for each dataset by 98%,
                         from 1.8GB+ to 30MB.
                    </li>
                    <li>
                        Implemented a web crawler, using headless Chrome with Selenium,
                         BeautifulSoup4 and routing over Tor to avoid request rate limiting.
                    </li>
                </ul>
            </div>,
    },
    {
        position: 'Backend Software Engineer Intern',
        company: 'PayPal',
        link: 'https://www.paypal.com',
        location: 'Singapore',
        period: '12/2017 - 05/2018',
        description:
            <ul className="tech-work-description xs-margin-bottom">
                <li>
                    Set up data dashboards for monitoring and analyzing Jenkins test builds,
                     using Dashing.
                </li>
                <li>
                    Built a web application to convert encoded test logs in Base64 to JSON,
                     for identifying test errors in sandboxes, using Plotly.
                </li>
                <li>
                    Built a web application to visualize error rates in sandboxes for developers
                     to identify error trends, using ReactJS and Flask.
                </li>
            </ul>,
    },
    {
        position: 'Software Engineer Intern',
        company: 'Beureka',
        link: 'https://beureka.com/',
        location: 'Singapore',
        period: '05/2017 - 07/2017',
        description:
            <ul className="tech-work-description xs-margin-bottom">
                <li>
                    Developed an Inventory Management System for monitoring and maintenance of
                     customer orders and stocked products.
                </li>
                <li>
                    Developed a back-end system integrating with AWS RDS and Lambda to fetch
                     customer orders and products’ details from Lazada and Qoo10.
                </li>
                <li>
                    Implemented an algorithm matching over 20000 customers for a point system
                     with 70% accuracy, using Record Linkage.
                </li>
            </ul>,
    },
    {
        position: 'System Engineer Intern',
        company: 'FutureRemedy Labs Pte. Ltd.',
        link: 'https://www.companies.sg/about/422744/FUTUREREMEDY-LABS-PTE-LTD-',
        location: 'Singapore',
        period: '12/2016 - 05/2017',
        description:
            <ul className="tech-work-description xs-margin-bottom">
                <li>
                    Refactored, optimized and added error handling for the water monitoring script,
                     increasing the stability by more than 80%.
                </li>
                <li>
                    Built a Flask application providing visual data records for remotely monitoring
                     water quality and pumps control.
                </li>
                <li>
                    Implemented a Master/Slave communication through Socket between multiple
                     Raspberry Pis, for pump control automation.
                </li>
            </ul>,
    },
];

const education = [
    {
        position: 'Bachelor of Engineering (Honours) in Computer Engineering',
        company: 'National University of Singapore (NUS)',
        link: 'http://nus.edu.sg/',
        location: 'Singapore',
        period: '07/2016 - Present',
    },
    {
        position: 'High School Graduation',
        company: 'Tran Dai Nghia - High School for the Gifted',
        link: 'https://trandainghia.edu.vn/',
        location: 'Vietnam',
        period: '08/2009 - 05/2016',
    },
];

const ResumeBodyWork = () => (
    <Container fluid>
        <Row noGutters>
            <Col xs="12" sm={{ size: 3, offset: 1 }}>
                <h3 className="tech-section-title">
                    Work Experience
                </h3>
            </Col>
            <Col xs="12" sm="8">
                {workExps.map(work => (
                    <div style={{ marginBottom: '50px' }} key={work.company}>
                        <h3 className="tech-heading">
                            {work.position}
                        </h3>
                        <p className="tech-work-details">
                            <a
                                href={work.link}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ fontSize: '18px' }}
                            >
                                {work.company}
                            </a>
                            &nbsp;&nbsp;|&nbsp;&nbsp;
                            <i><small>{work.location}</small></i>
                            &nbsp;&nbsp;|&nbsp;&nbsp;
                            <small>{work.period}</small>
                        </p>
                        {work.description}
                    </div>
                ))}
            </Col>
        </Row>
        <Row noGutters>
            <Col xs="12" sm={{ size: 3, offset: 1 }}>
                <h3 className="tech-section-title">
                    Education
                </h3>
            </Col>
            <Col xs="12" sm="8">
                {education.map(work => (
                    <div style={{ marginBottom: '50px' }} key={work.company}>
                        <h3 className="tech-heading">
                            {work.position}
                        </h3>
                        <p className="tech-work-details">
                            <a
                                href={work.link}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ fontSize: '18px' }}
                            >
                                {work.company}
                            </a>
                            &nbsp;&nbsp;|&nbsp;&nbsp;
                            <i><small>{work.location}</small></i>
                            &nbsp;&nbsp;|&nbsp;&nbsp;
                            <small>{work.period}</small>
                        </p>
                    </div>
                ))}
            </Col>
        </Row>
    </Container>
);

export default ResumeBodyWork;
