import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { faArrowAltCircleDown } from '@fortawesome/free-solid-svg-icons';
import { faGoogleDrive } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './ResumeDownloadFooter.css';

const ResumeDownloadFooter = () => (
    <div style={{ backgroundColor: '#e8fcc2' }}>
        <Container fluid>
            <Row noGutters>
                <Col xs="12" sm="5">
                    <div className="resume-footer-block-container">
                        <h3 className="resume-footer-heading">Download as a PDF</h3>
                        <small className="resume-footer-description">Get a printable and shareable version.</small>
                        <div className="resume-footer-button-container">
                            <a
                                href="https://drive.google.com/uc?export=download&id=149-NB8AfvwBP32A9rfctpsC7EpMgnduM"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="resume-footer-button"
                            >
                                <button
                                    className="bttn-fill bttn-md bttn-warning"
                                    type="button"
                                >
                                    <FontAwesomeIcon icon={faArrowAltCircleDown} />
                                    &nbsp;
                                    Download
                                </button>
                            </a>
                            <a
                                href="https://drive.google.com/open?id=149-NB8AfvwBP32A9rfctpsC7EpMgnduM"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="resume-footer-button"
                            >
                                <button
                                    className="bttn-fill bttn-md bttn-primary"
                                    type="button"
                                >
                                    <FontAwesomeIcon icon={faGoogleDrive} />
                                    &nbsp;
                                    Google Drive
                                </button>
                            </a>
                        </div>
                    </div>
                </Col>
                <Col xs="12" sm="2">
                    <div className="resume-footer-block-divider" />
                </Col>
                <Col xs="12" sm="5">
                    <div className="resume-footer-block-container">
                        <h3 className="resume-footer-heading">Not impressed ?</h3>
                        <small className="resume-footer-description">Have a look on some of my projects.</small>
                        <div className="resume-footer-button-container">
                            <a
                                href="https://wwww.snguyenthanh.com/portfolio"
                                target="_blank"
                                rel="noopener noreferrer"
                                className=""
                                style={{ textDecoration: 'none' }}
                            >
                                <button
                                    className="bttn-fill bttn-md bttn-primary"
                                    type="button"
                                >
                                    <i>Check Out My</i>
                                    <br />
                                    <strong>Awesome Portfolio</strong>
                                </button>
                            </a>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    </div>
);

export default ResumeDownloadFooter;
