import React from 'react';
import { Container, Row, Col } from 'reactstrap';

const dayToDayTechs = [
    'Python - Sanic / Django / Flask',
    'ReactJS / GatsbyJS',
    'PostgreSQL / MariaDB / MongoDB',
    'AWS / GCP / Firebase',
    'Material-UI / Bootstrap 4 / Bulma / Plotly',
    'CircleCI / SemaphoreCI / Travis CI / Jenkins',
    'Version Control ( Git )',
    'Linux (Ubuntu / Debian)',
];

const experienceTechs = [
    'Java / C',
    'Redux / React Native',
    'WebSocket / Protobuf',
    'Docker',
    'Kafka / Redis / Celery',
    'Twisted',
    'NginX',
];

const ResumeBodyTech = () => (
    <Container fluid style={{ marginBottom: '70px' }}>
        <Row noGutters>
            <Col xs="12" sm={{ size: 3, offset: 1 }}>
                <h3 className="tech-section-title">
                    Technologies
                </h3>
            </Col>
            <Col xs="12" sm="4">
                <h3 className="tech-heading">
                    DAY-TO-DAY USAGE
                </h3>
                <ul className="tech-text-container">
                    {dayToDayTechs.map(tech => (
                        <li key={tech}>{tech}</li>
                    ))}
                </ul>
            </Col>
            <Col xs="12" sm="4">
                <h3 className="tech-heading">
                    EXPERIENCED WITH
                </h3>
                <ul className="tech-text-container">
                    {experienceTechs.map(tech => (
                        <li key={tech}>{tech}</li>
                    ))}
                </ul>
            </Col>
        </Row>
    </Container>
);

export default ResumeBodyTech;
