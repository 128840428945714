import React from 'react';

import './ResumeHeaderText.css';

const ResumeHeaderText = () => (
    <div className="resume-header-container">
        <h1 className="resume-heading">
            {/* The */}
            <span className="heading-main">
                Resume
            </span>

        </h1>
    </div>
);

export default ResumeHeaderText;
